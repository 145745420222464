import React, { useEffect } from "react"
import {Helmet} from "react-helmet";
import { EventDetailsCard } from "../components/EventDetailsCard"
// import pasckathon from "../images/pasckathon.png"
import pasckathon2_0 from "../images/pasckathon2.0.png"
import pasckathon3 from "../images/pasckathon3.jpg"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import { toTop } from "../utils/scroll"
import {
  domain_svg_middle,
  domain_svg_top,
} from "../styles/domaincard.module.css"
import { StaticImage } from "gatsby-plugin-image"

const eventData = [
  {
    img: pasckathon3,
    title: "Pasckathon 3.0",
    dates: "4 to 15 December, 2021",
    link: "https://pict.acm.org/pasckathon30/",
    webLink: "/gallery#pasckathon",
    body: "Pasckathon 3.0 was open for all hackathon. It consisted of two rounds. For the first round, participants submitted a PPT with their problem statement, ideas, solutions, tech stack and case diagrams. During the second round, qualified teams implemented their idea. This round was for 2 days. Over 300 techies participated in this edition of Pasckthon & winning teams got exciting cash prizes of Rs. 50,000.",
  },
  {
    img: pasckathon2_0,
    title: "Pasckathon 2.0",
    dates: "20 to 26 September, 2020",
    link: "https://pict.acm.org/pasckathon20/",
    webLink: "/gallery#pasckathon",
    body: "Pasckathon 2.0 was conducted in an online fashion. It consisted of two rounds. The idea presentation, followed by the making of the prototype. Despite the online barriers, the Hackathon was a grand success. Altogether seventy-nine teams showed active participation in the first round, out of which twenty qualified for the second. Under the valuable guidance of the judges and mentors, the participants made the prototypes. The winning team got awarded a cash prize of Rs. 35,000.",
  },
  // {
  //   img: pasckathon,
  //   title: "Pasckathon",
  //   dates: "16 & 17 March, 2019",
  //   link: "https://pasckathon.in/",
  //   webLink: "/gallery#pasckathon",
  //   body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a sed neque dui eget feugiat potenti. Iaculis iaculis at vulputate vestibulum posuere egestas lectus diam cum. Risus non lobortis eget volutpat sit fringilla pellentesque purus quisque. Condimentum neque urna, consectetur accumsan, nullam non dictumst quam eget. Tempor, morbi leo maecenas at dis vulputate. Eget augue mi mauris mattis. Eu, arcu pellentesque blandit non non, eu fermentum, orci. Commodo non lacus id elit vitae. Ultrices eget ornare etiam adipiscing sollicitudin magna amet mauris erat. Potenti suspendisse ipsum, pellentesque sollicitudin ac. Dictum amet amet sed vulputate quis nulla. A tortor facilisi elit tellus aliquam viverra. Habitasse sed tortor venenatis, ipsum eget gravida leo donec. Porttitor enim.",
  // },
]

const Pasckathon = () => {
  useEffect(() => {
    toTop()
  })
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | Pasckathon</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="Pasckathon"
        image="pasckathon.png"
        text="PASC organizes an open hackathon where participants can use any technology to build their hacks and offer innovative ideas to augment their product. The meticulously compiled rounds help the participants boost their presentation skills and develop a problem-solving mindset. Usually, the first round comprises the idea presentation followed by the making of the prototype. The winning team gets awarded with certificates and exciting cash prizes."
      />
      <div className="py-8 lg:py-16 relative">
        <StaticImage
          src="../images/bg_svg_2.svg"
          className={`${domain_svg_top} hidden lg:block`}
        />
        <StaticImage
          src="../images/bg_svg_1.svg"
          className={`${domain_svg_middle} hidden lg:block`}
        />
        {eventData.map((event, index) => (
          <EventDetailsCard
            img={event.img}
            title={event.title}
            dates={event.dates}
            body={event.body}
            index={index}
            link={event.link}
            webLink={event.webLink}
          />
        ))}
      </div>
    </Layout>
  )
}

export default Pasckathon
